import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { useHistory } from 'react-router-dom';
import { Box, Drawer, Icon, Typography, Grid } from '@popmenu/common-ui';
import { X } from '@popmenu/web-icons';
import IconButton from '@material-ui/core/IconButton';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { createEvent } from '~/utils/eventable';
import { withCurrentSession } from '../CurrentSessionProvider';
import { withRestaurant } from '../../utils/withRestaurant';
import { classNames, withStyles } from '../../utils/withStyles';
import { getParam } from '../../utils/urls';

import { closeUserEmailCheckPrompt, closeVipV2Modal, setDidSubmitEmailWithFollowPrompt, setVipData, openProfileModal, openProfileV2Modal, openFavoriteLocationModal } from '../ModalActions';
import consumerUserByEmailQuery from '../../libs/gql/queries/users/consumerUserByEmailQuery.gql';

import AccessCodeSignInForm from '../sessions/AccessCodeSignInForm';
import AccessCodeValidationForm from '../sessions/AccessCodeValidation/AccessCodeValidationForm';
import OneLastStep from '../sessions/AccessCodeValidation/OneLastStep';
import AuthenticationPage from './AuthenticationPage';
import BasicModal from '../../admin/shared/BasicModal';
import EmailAuthenticationForm from './EmailAuthenticationForm';
import Loading from '../Loading';
import MagicLinkSentPage from './MagicLinkSentPage';
import VipV2ModalStyles from './styles';
import NewFollowerInfoPage from './NewFollowerInfoPage';
import SignInV2Form from '../sessions/SignInV2Form';
import SignInVerifiedPage from './SignInVerifiedPage';
import SignUpV2Form from '../sessions/SignUpV2Form';
import SignUpSuccessPage from './SignUpSuccessPage';
import PopmenuRed from '../../assets/svg/popmenu_icon_red.svg';
import Query from '../Query';
import { useFeatureFlags } from '../../utils/featureFlagsContext';
import { executeWithProgressBar } from '../../utils/postponed';
import { getUserEntryPoint } from '../sessions/utils';

export const goToMagicLinkSentPage = (userData, useMagicLink) => (
  useMagicLink && userData);

export const goToNewFollowerPage = (userData, showFollowerInfoPage, isLoggedIn, useValidationAccessCode) => (
  userData && ('isFollower' in userData && !userData.isFollower) && showFollowerInfoPage && !useValidationAccessCode && !isLoggedIn);

export const goToSignUpSuccessPage = (userData, isLoggedIn, userEmail, showSignInForm, isMagicLinkPath, useMagicLink) => (
  isLoggedIn && ((!userData && !isMagicLinkPath) || (!userEmail && !showSignInForm && !isMagicLinkPath && !useMagicLink)));

export const goToSignUpFormPage = (userData, isMagicLinkPath, userEmail, showSignInForm) => (
  (!userData && !isMagicLinkPath) || (!userEmail && !showSignInForm && !isMagicLinkPath));

export const goToSignInVerifiedPage = (userData, isLoggedIn) => (
  isLoggedIn && (userData || isLoggedIn));

export const goToUseAccessCodePage = (userData, useAccessCode) => (
  useAccessCode && userData);

export const goToSignInPage = (userData, isLoggedIn) => (
  !isLoggedIn && userData);

export const goToValidationAccessCodePage = (userData, useValidationAccessCode, isLoyaltyEnabled, newUserPhone) => (
  useValidationAccessCode && isLoyaltyEnabled && ((userData && !userData.isTextValidated) || newUserPhone));

export const goToOneLastStepPage = (userData, useValidationAccessCode, isLoyaltyEnabled, newUserPhone, useDifferentNumber) => (
  useValidationAccessCode && isLoyaltyEnabled && ((!newUserPhone && userData && !userData.lastFourDigitsPhoneNumber) || useDifferentNumber) && !userData.isTextValidated);

// eslint-disable-next-line max-lines-per-function
const VipV2Modal = ({ classes, isLoggedIn, refetchUser, width, restaurant }) => {
  const history = useHistory();
  const [useAccessCode, setUseAccessCode] = useState(false);
  const [useValidationAccessCode, setUseValidationAccessCode] = useState(false);
  const [useDifferentNumber, setUseDifferentNumber] = useState(false);
  const [useMagicLink, setUseMagicLink] = useState(false);
  const [userData, setUserData] = useState();
  const [newUserName, setNewUserName] = useState();
  const [showFollowerInfoPage, setShowFollowerInfoPage] = useState(true);
  const [favoriteLocationId, setFavoriteLocationId] = useState();
  const [newUserPhone, setNewUserPhone] = useState();

  const showVipV2Modal = useSelector(state => state.modals.showVipV2Modal);
  const vipData = useSelector(state => state.modals.vipData);

  const { isMagicLinkPath, isPopPath, isReservationPath, isReviewPath, isWaitlistPath, isBecomeVipModal, userEmail, showSignInForm } = vipData;

  const { isFeatureActive } = useFeatureFlags();
  const isLoyaltyEnabled = isFeatureActive('isLoyaltyEnabled');

  const isMobile = isWidthDown('md', width);

  const dispatch = useDispatch();

  const closeModal = () => {
    setUseAccessCode(false);
    setUseDifferentNumber(false);
    setUseMagicLink(false);
    setUseValidationAccessCode(false);
    setShowFollowerInfoPage(true);
    setNewUserPhone(null);
    dispatch(setDidSubmitEmailWithFollowPrompt(false));
    dispatch(setVipData());
    dispatch(closeVipV2Modal());
    if (getParam('magic_link') === 'show') {
      history.push('/');
    }
  };

  const goToProfileModal = (tab) => {
    closeModal();
    if (restaurant && restaurant.featureSetting && restaurant.featureSetting.isProfileV2Enabled) {
      dispatch(openProfileV2Modal(tab));
    } else {
      dispatch(openProfileModal());
    }
  };

  const goToFavoriteLocationModal = () => {
    closeModal();
    dispatch(openFavoriteLocationModal());
  };

  const followerSource = useMemo(() => getUserEntryPoint(
    isWaitlistPath,
    isBecomeVipModal,
    isReservationPath,
  ), [isBecomeVipModal, isReservationPath, isWaitlistPath]);

  let googleAnalyticsCloseTitle;

  const displayForm = (data, loading) => {
    if (loading) {
      googleAnalyticsCloseTitle = 'vip_v2_loading_close';
      return <Loading size="xl" />;
    }
    console.log(`[POPMENU] User logged in flag: ${isLoggedIn}`);
    const goToAuthenticationPage = (!userEmail && showSignInForm && !isLoggedIn && !useMagicLink && !useValidationAccessCode);
    const goToEmailAuthenticationPage = ((isPopPath || isReviewPath) && !userEmail && !isLoggedIn);

    if (goToAuthenticationPage) {
      // When a user uses the sign in nav link
      googleAnalyticsCloseTitle = 'vip_v2_authentication_page_close';
      return (
        <AuthenticationPage
          useMagicLink={() => setUseMagicLink(true)}
          setUserData={setUserData}
          setUseValidationAccessCode={setUseValidationAccessCode}
        />
      );
    } else if (goToEmailAuthenticationPage) {
      googleAnalyticsCloseTitle = isPopPath ? 'v2_pop_path_authentication_page_close' : 'v2_review_path_authentication_page_close';

      return (
        <EmailAuthenticationForm />
      );
    } else if (goToOneLastStepPage(userData, useValidationAccessCode, isLoyaltyEnabled, newUserPhone, useDifferentNumber)) {
      return (
        <OneLastStep
          setNewUserPhone={setNewUserPhone}
          userEmail={userEmail}
          restaurantId={restaurant.id}
          setUseDifferentNumber={setUseDifferentNumber}
          isLoggedIn={isLoggedIn}
          userData={data}
        />
      );
    } else if (goToValidationAccessCodePage(data, useValidationAccessCode, isLoyaltyEnabled, newUserPhone)) {
      return (
        <AccessCodeValidationForm
          lastFour={data?.lastFourDigitsPhoneNumber || newUserPhone?.slice(-4)}
          newUserPhone={newUserPhone}
          setUseDifferentNumber={setUseDifferentNumber}
          setUseValidationAccessCode={setUseValidationAccessCode}
          userEmail={userEmail}
          restaurantId={restaurant.id}
          isLoggedIn={isLoggedIn}
        />
      );
    } else if (goToNewFollowerPage(data, showFollowerInfoPage, isLoggedIn, useValidationAccessCode)) {
      // When a user is has an account but is not a follower show new follower info page
      googleAnalyticsCloseTitle = 'v2_already_vip_close';
      return (
        <NewFollowerInfoPage
          restaurantName={restaurant.name}
          showSignInForm={() => {
            setShowFollowerInfoPage(false);
          }}
          showSignUpForm={() => {
            dispatch(setVipData());
            setShowFollowerInfoPage(false);
          }}
          userData={data}
          userEmail={userEmail}
          offerMessageCampaignCount={restaurant.offerMessageCampaignCount}
        />
      );
    } else if (goToSignUpSuccessPage(data, isLoggedIn, userEmail, showSignInForm, isMagicLinkPath, useMagicLink)) {
      // When a user is new and uses the sign up link or prompt

      googleAnalyticsCloseTitle = 'v2_success_close';
      return (
        <SignUpSuccessPage
          firstName={newUserName}
          closeModal={closeModal}
          goToProfileModal={goToProfileModal}
          restaurant={restaurant}
        />
      );
    } else if (goToSignUpFormPage(data, isMagicLinkPath, userEmail, showSignInForm)) {
      googleAnalyticsCloseTitle = 'v2_sign_up_close';
      return (
        <SignUpV2Form
          restaurant={restaurant}
          onExistingUser={(email) => {
            if (!useValidationAccessCode && !isLoyaltyEnabled) {
              dispatch(setVipData({ userEmail: email }));
              setShowFollowerInfoPage(true);
            }
          }}
          userData={data}
          userEmail={userEmail}
          setNewUserName={setNewUserName}
          setNewUserPhone={setNewUserPhone}
          isWaitlistPath={isWaitlistPath}
          isBecomeVipModal={isBecomeVipModal}
          isReservationPath={isReservationPath}
          setFavoriteLocationId={setFavoriteLocationId}
          setUseValidationAccessCode={setUseValidationAccessCode}
        />
      );
    } else if (goToSignInVerifiedPage(data, isLoggedIn)) {
      // When user has an account, used for followers and non-followers
      googleAnalyticsCloseTitle = 'vip_v2_verified_page_close';
      return (
        <SignInVerifiedPage
          closeModal={closeModal}
          goToFavoriteLocationModal={goToFavoriteLocationModal}
          goToProfileModal={goToProfileModal}
          restaurant={restaurant}
          usedAccessCode={useAccessCode}
          usedMagicLink={isMagicLinkPath}
          userOffersCount={data?.orderingOfferCodesCount}
        />
      );
    } else if (goToUseAccessCodePage(data, useAccessCode)) {
      googleAnalyticsCloseTitle = 'v2_access_code_close';
      return (
        <AccessCodeSignInForm
          restaurantId={restaurant.id}
          userData={data}
          userEmail={userEmail}
          closeModal={closeModal}
          refetchUser={refetchUser}
          source={followerSource}
        />
      );
    } else if (goToMagicLinkSentPage(data, useMagicLink)) {
      googleAnalyticsCloseTitle = 'v2_magic_link_close';
      return (
        <MagicLinkSentPage
          closeVipModal={closeModal}
          userEmail={userEmail}
        />
      );
    } else if (goToSignInPage(data, isLoggedIn)) {
      googleAnalyticsCloseTitle = 'vip_v2_sign_in_form_close';
      return (
        <SignInV2Form
          favoriteLocationId={favoriteLocationId}
          restaurantId={restaurant.id}
          restaurantName={restaurant.name}
          refetchUser={refetchUser}
          useAccessCode={() => setUseAccessCode(true)}
          requireValidation={() => setUseValidationAccessCode(true)}
          useMagicLink={() => setUseMagicLink(true)}
          userData={data}
          userEmail={userEmail}
          followerSource={followerSource}
        />
      );
    }
    return null;
  };

  const backgroundImageUrl = restaurant.signUpBackgroundImageUrl ||
  restaurant.photoUrl ||
  (restaurant.homePage && restaurant.homePage.heroImages && restaurant.homePage.heroImages[0] && restaurant.homePage.heroImages[0].imageUrl);

  if (!showVipV2Modal) {
    return null;
  }
  if (isMobile) {
    return (
      <Drawer
        anchor="right"
        open
        classes={{
          paper: classes.drawer,
        }}
      >
        <Query
          query={consumerUserByEmailQuery}
          fetchPolicy="cache-and-network"
          skip={!userEmail}
          variables={{
            restaurantId: restaurant.id,
            userEmail: userEmail || '',
          }}
        >
          {({ data, loading }) => (
            <Box
              marginX={1.5}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box>
                <Box textAlign="right" marginTop={1}>
                  <IconButton
                    aria-label="close_vip_v2_drawer"
                    onClick={() => executeWithProgressBar(() => {
                      dispatch(closeUserEmailCheckPrompt());
                      closeModal();
                    })}
                    icon="cancel"
                    size="small"
                  >
                    <Icon icon={X} size="extra-large" />
                  </IconButton>
                </Box>
                {displayForm(data?.consumerUserByEmail || userData, loading)}
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                paddingY={1.5}
              >
                <Typography className={classes.footerText}>
                  <FormattedMessage id="sessions.footer_text" defaultMessage="Powered By" />
                </Typography>
                <Icon icon={PopmenuRed} alt="popmenu" className={classes.popmenuLogo} aria-label="popmenu" />
              </Box>
            </Box>
          )}
        </Query>
      </Drawer>
    );
  }

  return (
    <BasicModal
      className="pm-become-vip-v2-modal"
      closeModal={() => {
        createEvent({
          eventableType: 'BecomeVipV2',
          eventType: googleAnalyticsCloseTitle || 'vip_v2_modal_close',
        });
        closeModal();
      }}
      show={showVipV2Modal}
      size="md"
      PaperProps={{
        'aria-describedby': 'become-vip-description',
        'aria-labelledby': 'become-vip-title',
      }}
    >
      <Query
        query={consumerUserByEmailQuery}
        fetchPolicy="cache-and-network"
        skip={!userEmail}
        variables={{
          restaurantId: restaurant.id,
          userEmail: userEmail || '',
        }}
      >
        {({ data, loading }) => (
          <Grid container alignItems="stretch" spacing={8}>
            <Grid
              className={classNames(classes.logoColumn, 'pm-vip-v2-modal-logo-container')}
              item
              lg={4}
              md={3}
              xs={12}
              style={{
                backgroundImage: backgroundImageUrl ? `url("${backgroundImageUrl}")` : 'none',
              }}
            >
              {restaurant.logoUrl && (
                <div className={classNames(restaurant.signUpSetting.signUpLogoEffect === 'sule_none' ? null :
                  restaurant.signUpSetting.signUpLogoEffect === 'sule_lighten' ? classes.suleLighten :
                    classes.suleDarken, 'pm-vip-v2-modal-restaurant-logo')}
                >
                  <img
                    alt={restaurant.name}
                    className={classes.restaurantLogo}
                    src={restaurant.logoUrl}
                  />
                </div>
              )}
            </Grid>
            <Grid item lg={8} md={9} xs={12} className="pm-vip-sign-up-v2-form-container">
              {
                displayForm(data?.consumerUserByEmail || userData, loading)
              }
              <div className={classes.footerContainer}>
                <Typography className={classes.footerText}>
                  <FormattedMessage id="sessions.footer_text" defaultMessage="Powered By" />
                </Typography>
                <Icon icon={PopmenuRed} alt="popmenu" className={classes.popmenuLogo} aria-label="popmenu" />
              </div>
            </Grid>
          </Grid>
        )}
      </Query>
    </BasicModal>
  );
};

VipV2Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  restaurant: PropTypes.shape({
    customFollowPath: PropTypes.string,
    followButtonCta: PropTypes.string,
    followHeadingCta: PropTypes.string,
    followSubheadingCta: PropTypes.string,
  }).isRequired,
};

export default compose(
  withCurrentSession,
  withStyles(VipV2ModalStyles),
  withRestaurant,
  mapProps(({ currentSession, ...props }) => ({
    ...props,
    isLoggedIn: !!currentSession.user,
    refetchUser: currentSession.refetch,
  })),
)(withWidth()(VipV2Modal));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';

import { createEvent } from '~/utils/eventable';
import { openProfileModal, openProfileV2Modal, openVipV2Modal, setVipData } from '../../../shared/ModalActions';
import { setMenuItemCartLocationId, setMenuItemCartIsScheduled } from '../../../shared/MenuItemCartActions';
import { withWindowContext } from '../../../shared/WindowProvider';
import { withWindowSizeContext } from '../../../shared/WindowSizeProvider';

import { closestElement } from '../../../utils/dom';
import { nl2br } from '../../../utils/utils';
import { readableFontColor } from '../../../utils/colors';
import { withCurrentSession } from '../../../shared/CurrentSessionProvider';
import { withIntl } from '../../../utils/withIntl';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { classNames, withStyles } from '../../../utils/withStyles';
import styles from './styles';

import IntersectionObserverWrapper from './IntersectionObserverWrapper';
import NavItemsDropdown from '../NavItemsDropdown';
import DropdownNavLink from './DropdownNavLink';
import { executeWithProgressBar } from '../../../utils/postponed';

const NavItemsV2 = (props) => {
  const [collectiveVisibilityMap, setCollectiveVisibilityMap] = useState({});

  const navItemStyles = (isFeatured, navItemUrl, currentDropdown, isDropdownLink) => {
    const currentPage = props.location.pathname === navItemUrl;
    if ((currentPage || currentDropdown) && props.theme.isActiveNavlinkColorEnabled) {
      const backgroundEffect = props.theme.headerBackgroundEffect.includes('black') ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)';
      if (isFeatured) {
        return {
          backgroundColor: props.theme.primaryColor || '#F04649',
          color: readableFontColor(props.theme.primaryColor || '#F04649', (props.theme.activeNavlinkColor || props.theme.primaryColor || '#F04649')),
        };
      }
      let bgColor = props.theme.navbarBgColor;
      if (isDropdownLink && !bgColor) {
        bgColor = 'rgba(0, 0, 0, 1)';
      } else if (!bgColor) {
        bgColor = props.scrolledHeader ? backgroundEffect : null;
      }
      return {
        color: readableFontColor(bgColor, (props.theme.activeNavlinkColor || props.theme.primaryColor || '#F04649')),
      };
    }
    if (isFeatured) {
      return {
        backgroundColor: props.theme.primaryColor || '#F04649',
        color: readableFontColor(props.theme.primaryColor || '#F04649'),
      };
    }
    if (props.noBackground || (!props.theme.headerNavFontColor && !props.theme.navbarBgColor)) {
      return {};
    }
    if (props.theme.headerNavFontColor && !props.theme.navbarBgColor) {
      return {
        color: props.theme.headerNavFontColor,
      };
    }
    return {
      color: readableFontColor(props.noBackground ? null : props.theme.navbarBgColor, props.theme.headerNavFontColor),
    };
  };

  const signInLink = (styling, classes = null, key = null, linkName = null, navItem = null) => (
    <button
      key={key}
      className={classNames('a pm-link-sign-in nav-drop-down', classes)}
      onClick={() => {
        if (props.isPreview) {
          if (navItem) props.sectionAction(navItem);
          return;
        }
        executeWithProgressBar(() => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'v2_sign_in_nav_link',
          });
          createEvent({
            eventableType: 'BecomeVip',
            eventLabel: 'v2 | main_nav_link | sign_in',
            eventType: 'sign_in_start_click',
          });
          props.setVipData({ showSignInForm: true });
          props.openVipV2Modal();
          props.onClick();
        });
      }}
      style={{ whiteSpace: 'noWrap', ...styling }}
      type="button"
      data-cy="sign-in-navigation-bar"
      ref={navItem?.useRef}
      onKeyDown={navItem?.onKeyDown}
    >
      {linkName || props.t('sessions.sign_in')}
    </button>
  );

  const showNavSignUp = (styling, classes = null, key = null, linkName = null, navItem = null) => {
    const onOpenProfile = () => {
      if (props.restaurant.featureSetting.isProfileV2Enabled) {
        props.openProfileV2Modal('my_profile');
      } else {
        props.openProfileModal();
      }
    };

    if (!props.isPreview && props.isUser) {
      linkName = <FormattedMessage id="profile.nav_item_my_profile" defaultMessage="My Profile" />; // eslint-disable-line no-param-reassign
    }
    if (props.isUser) {
      return (
        <button
          className="a"
          onClick={() => {
            if (props.isPreview) {
              if (navItem) props.sectionAction(navItem);
              return;
            }
            executeWithProgressBar(() => {
              onOpenProfile();
              props.onClick();
            });
          }}
          style={{ whiteSpace: 'noWrap', ...styling }}
          type="button"
          ref={navItem?.useRef}
          onKeyDown={navItem?.onKeyDown}
        >
          {linkName}
        </button>
      );
    }
    return (
      <button
        key={key}
        className={classNames('a pm-link-my-profile nav-drop-down', classes)}
        data-cy="become-a-vip-navigation-bar"
        onClick={(e) => {
          e.persist();
          executeWithProgressBar(() => {
            if (props.restaurant.customFollowPath) {
              window.open(props.restaurant.customFollowPath, '_blank').focus();
            } else {
              try {
                createEvent({
                  eventableType: 'BecomeVipV2',
                  eventType: 'v2_nav_link',
                });
                props.setVipData({ isBecomeVipModal: true });
                props.openVipV2Modal();
                props.onClick();
                closestElement(e.target, 'button').blur();
              } catch (err) {
                console.warn(err);
              }
            }
          });
        }}
        style={{ whiteSpace: 'noWrap', ...styling }}
        type="button"
        ref={navItem?.useRef}
        onKeyDown={navItem?.onKeyDown}
      >
        {linkName}
      </button>
    );
  };

  const renderLink = (navItem, dropdownLink = false) => {
    const key = `${navItem.__typename}_${navItem.id}`;
    const currentDropdown = navItem.__typename.startsWith('RestaurantNavDropdown') && navItem.navLinks.some(link => link.url === props.location.pathname);
    const navBarItemStyles = navItemStyles(!!navItem.isFeatured, navItem.url, currentDropdown, dropdownLink);
    const pmLinkClass = `${navItem.isFeatured ? 'pm-link-featured ' : ''}pm-link`;
    let linkName;
    if (navItem.thumbnailUrl) {
      linkName = (
        <div className={props.classes.navLinkImgContainer}>
          <img
            alt=""
            className={props.classes.navLinkImg}
            src={navItem.thumbnailUrl}
          />
          {' '}
          {navItem.name}
        </div>
      );
    } else {
      linkName = dropdownLink ? nl2br(navItem.name) : navItem.name;
    }
    if (navItem.__typename.startsWith('RestaurantNavDropdown')) {
      // dropdown on mobile view if 5 or more links
      if (navItem.navLinks.length >= 5 && props.isMobile) {
        return (
          <NavItemsDropdown
            linkName={linkName}
            navItem={navItem}
            navItemStyles={navBarItemStyles}
            noBackground={props.noBackground}
            renderLink={renderLink}
            theme={props.theme}
          />
        );
      }
      // dropdown if 5 or fewer links
      const backgroundColor = props.noBackground ? null : (navItem.isFeatured ? props.theme.primaryColor : props.theme.navbarBgColor);
      return (
        <DropdownNavLink
          linkName={linkName}
          navItem={navItem}
          navBarItemStyles={navBarItemStyles}
          backgroundColor={backgroundColor}
          renderLink={renderLink}
          theme={props.theme}
          isMobile={props.isMobile}
          key={key}
        />
      );
    }
    if (navItem.linkType === 'sign_up_type') {
      return showNavSignUp(navBarItemStyles, pmLinkClass, key, linkName, navItem);
    }
    if (navItem.linkType === 'sign_in_type') {
      if (props.isUser && !props.isPreview) {
        return null;
      }
      return (
        signInLink(navBarItemStyles, pmLinkClass, key, linkName, navItem)
      );
    }
    if (navItem.url && navItem.url.indexOf('/') === 0 && navItem.url.indexOf('/files/') !== 0) {
      const isOrderingPage = navItem.url.includes('order') || navItem.url.includes('getting-started') || (navItem.url.includes('menu') && navItem.url.includes('?location'));
      if (navItem.disableAjaxLoad) {
        return (
          <a
            key={key}
            className={pmLinkClass}
            data-event-tracking={navItem.url.includes('popmenu-order') && 'order-online-link'}
            href={navItem.url}
            onClick={(e) => {
              props.onClick();
              closestElement(e.target, 'a').blur();
              if (isOrderingPage) {
                createEvent({
                  eventableType: 'OnlineOrdering',
                  eventType: 'nav_link_order_button',
                });
              }
            }}
            style={navBarItemStyles}
            ref={navItem?.useRef}
            onKeyDown={navItem?.onKeyDown}
          >
            {linkName}
          </a>
        );
      } else {
        return (
          <Link
            key={key}
            className={pmLinkClass}
            onClick={(e) => {
              if (props.isPreview) {
                props.sectionAction(navItem);
                e.stopPropagation();
                e.preventDefault();
                return;
              }
              if (navItem.linkType === 'custom_page_type') {
                if (navItem.locationId) {
                  props.setMenuItemCartLocationId(navItem.locationId);
                }
                props.setMenuItemCartIsScheduled(null);
              }
              if (isOrderingPage) {
                createEvent({
                  eventableType: 'OnlineOrdering',
                  eventType: 'nav_link_order_button',
                });
              }
              props.onClick();
              closestElement(e.target, 'a').blur();
            }}
            style={navBarItemStyles}
            to={navItem.url}
            ref={navItem?.useRef}
            onKeyDown={navItem?.onKeyDown}
          >
            {linkName}
          </Link>
        );
      }
    }
    if (navItem.url) {
      return (
        <a
          key={key}
          aria-describedby={navItem.isExternal ? 'aria-new-window-2' : null}
          className={pmLinkClass}
          href={navItem.url}
          onClick={() => {
            props.onClick();
          }}
          rel={navItem.isExternal ? 'noopener' : null}
          style={navBarItemStyles}
          target={navItem.isExternal ? '_blank' : null}
          ref={navItem?.useRef}
          onKeyDown={navItem?.onKeyDown}
        >
          {linkName}
        </a>
      );
    }
    return null;
  };

  const links = []; // Array of { id: "link-id", link: <Link /> }
  const navBarItemStyles = navItemStyles(false);
  // Custom Links (overrides default links
  props.restaurant.navItems.forEach((navItem) => {
    // Skip over disabled Custom Pages
    if (navItem.linkType === 'custom_page_type') {
      const customPage = props.restaurant.customPages.filter(cp => cp.id === Number(navItem.targetId))[0];
      if (!customPage || !customPage.isEnabled) {
        return;
      }
    }
    links.push({
      id: `${navItem.__typename}_${navItem.id}`,
      isFeatured: navItem.isFeatured,
      link: renderLink(navItem),
    });
  });

  const vipLinks = [];
  // Sign in/Profile
  if (props.restaurant.showNavSignUp) {
    const signupType = props.restaurant.navItems.filter(item => (item.linkType === 'sign_up_type'));
    if (props.isUser) {
      // Push Profile as last item if sign_up_type does not exist
      if (signupType.length === 0) {
        vipLinks.push({
          id: 'profile',
          link: (
            showNavSignUp({ ...navBarItemStyles })
          ),
        });
      }
    } else if (signupType.length === 0) {
      vipLinks.push({
        id: 'signup',
        link: (
          showNavSignUp(navBarItemStyles, null, null, props.restaurant.signUpHeadingCta)
        ),
      });
      if (!props.restaurant.customFollowPath) {
        vipLinks.push({
          id: 'signinV2',
          link: (
            signInLink({ ...navBarItemStyles, ...{ margin: 'auto' } })
          ),
        });
      }
    }
  }

  // Split links into two groups
  let groups;
  if (props.isSplit) {
    const linksLength = props.restaurant.showNavSignUp ? links.length + 1 : links.length;
    groups = [
      links.splice(0, Math.ceil(linksLength / 2.0)),
      links,
    ];
  } else {
    groups = [links.filter(item => item.link)];
  }

  const backgroundColor = props.noBackground ? null : props.theme.navbarBgColor; // more not featurable for now

  const moreDropdownLinks = (
    groups.map(group => (
      group.map(({
        id,
        link,
      }) => {
        if (!collectiveVisibilityMap[id]) {
          return (
            <div key={id} data-targetid={id}>
              {link}
            </div>
          );
        }
        return null;
      })
    ))
  );

  const renderGroup = (group, i) => {
    const showOverflowSection = (props.isSplit && i === 1) || (!props.isSplit);
    return (
      props.isMobile ? (
        <ul
          key={`nav_${i}`}
          id={props.id}
          className={classNames(props.className, `nav-ul-${i}`, props.classes.navModal)}
        >
          {group.map(({
            id,
            link,
          }) => (
            <li key={id} data-targetid={id}>
              {link}
            </li>
          ))}
          {vipLinks.map(({ id, link }) => (
            <li key={id}>
              {link}
            </li>
          ))}
        </ul>
      ) : (
        <IntersectionObserverWrapper
          backgroundColor={backgroundColor}
          className={props.className}
          isSplit={props.isSplit}
          moreDropdownLinks={moreDropdownLinks}
          navItemStyles={navItemStyles}
          setCollectiveVisibiltyMap={setCollectiveVisibilityMap}
          showOverflowSection={showOverflowSection}
          vipLinks={vipLinks}
        >
          {group.map(({
            id,
            link,
          }) => (
            <li key={id} data-targetid={id}>
              {link}
            </li>
          ))}
        </IntersectionObserverWrapper>
      )
    );
  };

  return (
    groups.map((group, i) => (
      <div
        key={`nav_${i}`}
        id={props.id}
        className={classNames(props.className, `nav-ul-${i}`, props.classes.navWrapper, props.isSplit && i === 0 ? props.classes.leftNav : null, props.isSplit && i === 1 ? props.classes.rightNav : null, props.isMobile ? props.classes.navModal : null)}
      >
        {renderGroup(group, i)}
      </div>
    ))
  );
};

NavItemsV2.defaultProps = {
  className: null,
  id: null,
  isPreview: false,
  noBackground: false,
  onClick: () => { console.log('[POPMENU] Nav link clicked...'); },
  sectionAction: null,
};

NavItemsV2.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool,
  isSplit: PropTypes.bool.isRequired,
  isUser: PropTypes.bool.isRequired,
  noBackground: PropTypes.bool,
  onClick: PropTypes.func,
  openProfileModal: PropTypes.func.isRequired,
  openProfileV2Modal: PropTypes.func.isRequired,
  openVipV2Modal: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    customPages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      tabName: PropTypes.string,
    })),
    locations: PropTypes.arrayOf(PropTypes.shape({
      enabledMenus: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      })),
      name: PropTypes.string,
      slug: PropTypes.string,
    })),
    navItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    showNavSignUp: PropTypes.bool,
    signUpHeadingCta: PropTypes.string,
  }).isRequired,
  sectionAction: PropTypes.func,
  setMenuItemCartIsScheduled: PropTypes.func.isRequired,
  setMenuItemCartLocationId: PropTypes.func.isRequired,
  setVipData: PropTypes.func.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  connect(() => ({}), {
    openProfileModal,
    openProfileV2Modal,
    openVipV2Modal,
    setMenuItemCartIsScheduled,
    setMenuItemCartLocationId,
    setVipData,
  }),
  withCurrentSession,
  mapProps(({ currentSession, ...props }) => ({
    ...props,
    isUser: !!currentSession.user,
  })),
  withIntl,
  withRouter,
  withStyles(styles),
  withTheme,
  withWindowContext,
  mapProps(({ window, ...props }) => ({
    ...props,
    scrolledHeader: window.scrolledHeader,
  })),
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
  })),
)(NavItemsV2);

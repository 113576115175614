/* eslint-disable @typescript-eslint/no-floating-promises */
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { isSSR } from './dom';
import { prefetchBundles } from './latePrefetch';
import { countElementsInViewport } from './isVisible';
import { earlyVisibilityOptions } from '../shared/MultiSectionLazyContainer';
import { visualRegressionsMode } from './visualRegressionsMode';

const pageFullyLoaded = () => new Promise<void>((resolve) => {
  const appDiv = document.getElementById('consumer-app') ?? document.getElementById('popmenu-admin-app');
  const isModernBrowser = window.popmenuIsModernBrowser && window.popmenuIsModernBrowser();
  // In visual regression mode lodash.debounce doesn't work because Date.now() is stubbed.
  if (!isModernBrowser || !appDiv || visualRegressionsMode) {
    resolve();
    return;
  }
  let observer: MutationObserver | null = null;
  const onMutation = debounce(() => {
    if (countElementsInViewport('.MuiSkeleton-root', earlyVisibilityOptions) > 0 ||
          countElementsInViewport('.MuiCircularProgress-root') > 0 ||
          countElementsInViewport('.mapboxgl-loading') > 0 ||
          countElementsInViewport('.ecwid-pb-placeholder') > 0) {
      return;
    }
    resolve();
    observer?.disconnect();
  }, 50);
  observer = new MutationObserver(onMutation);
  observer.observe(appDiv, {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  });
  onMutation();
});

export let startTasksPendingHydration : () => void = () => undefined;

// Safari doesn't support the effectiveType API, so we presume fast connection.
// @ts-expect-error - https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
const FAST_CONNECTION = !isSSR && [undefined, '4g'].includes(window.navigator.connection?.effectiveType);
export const LIGHTHOUSE_DELAY_MS = FAST_CONNECTION ? 6000 : 18000;

const hydrationFinishedPromise = isSSR ? null : new Promise<void>((resolve) => {
  startTasksPendingHydration = resolve;
});

const firstUserInteractionPromise = isSSR ? null : new Promise<void>((resolve) => {
  // This function is attached to window to be accessible from cypress tests.
  window.popmenuResolveFirstInteractionTimeout = () => {
    resolve();
    performance.mark('firstInteractionTimeout');
  };

  runAfter('hydrationFinished', async () => {
    setTimeout(() => window.popmenuResolveFirstInteractionTimeout?.(), LIGHTHOUSE_DELAY_MS);
    await window.popmenuFirstInteraction;
    // Lighthouse doesn't interact with the page, if there is an interaction
    // it means it's not a Lighthouse session. So, after popmenuFirstInteraction
    // we can start executing unimportant tasks without LH regressions.
    // But we still need to wait for all skeletons and loaders to disappear
    // to ensure we don't harm actual user experience.
    await pageFullyLoaded();
    performance.mark('pageFullyLoaded');
    localStorage.setItem('popmenuFirstSessionLoaded', 'true');
    resolve();
  });
});

const firstSessionLoadedPromise = isSSR ? null : new Promise<void>((resolve) => {
  if (localStorage.getItem('popmenuFirstSessionLoaded') !== 'true') {
    firstUserInteractionPromise?.then(() => {
      resolve();
    });
  } else {
    resolve();
  }
});

// Events Timeline for the first session:
// 1. hydrationFinished
// 2. users interacts with the page
// 3. all visible skeletons and loaders disappear
// 4. firstUserInteraction, firstSessionLoaded
//
// Events Timeline for the subsequent sessions:
// 1. firstSessionLoaded
// 2. hydrationFinished
// 3. users interacts with the page
// 4. all visible skeletons and loaders disappear
// 5. firstUserInteraction
type PageLoadStage = 'hydrationFinished' | 'firstUserInteraction' | 'firstSessionLoaded';

// CAUTION: DO NOT TRICK LIGHTHOUSE USING THIS METHOD!!!
// Anything executed after firstUserInteraction and firstSessionLoaded will be ignored by lighthouse.
// Use it ONLY for unimportant tasks.
// For example, when postponing hi-fi images, ensure lo-fi placheolders look good!
export async function runAfter<T>(stage: PageLoadStage, callback: () => T) {
  if (isSSR) return null;

  switch (stage) {
    case 'hydrationFinished':
      await hydrationFinishedPromise;
      break;
    // Will wait 6 seconds. If user interacts with the page before 6 seconds, it will start sooner.
    // It will wait for all skeletons to disappear and trigger the callback.
    // Use it for unimportant tasks disturbing lighthouse scores (metrics, data prefetching, etc.)
    case 'firstUserInteraction':
      await firstUserInteractionPromise;
      break;
    // Similar to 'firstUserInteraction', but works only when user visits the page for the first time.
    // Use to fetch data that is not needed in the beginning of the first visit of the page (session query, hi-fi header image etc.)
    //
    // CAUTION:  Be careful! The first impression is important! The page must look good initially.
    case 'firstSessionLoaded':
      await firstSessionLoadedPromise;
      break;
    default: throw new Error(`Unknown stage ${stage}`);
  }

  return callback();
}

// See comments for runAfter.
export function usePageLoadStageFinished(stage: PageLoadStage) {
  const [finished, setFinished] = useState(false);
  useEffect(() => {
    runAfter(stage, () => {
      setFinished(true);
    });
  }, [stage]);
  return finished;
}

runAfter('firstUserInteraction', prefetchBundles);

runAfter('hydrationFinished', () => {
  if (window.popmenuInitFbq) {
    setTimeout(window.popmenuInitFbq, LIGHTHOUSE_DELAY_MS);
  }

  window.popmenuHydrationFinished = true;
});

runAfter('firstSessionLoaded', () => {
  performance.mark('firstSessionLoaded');
});

// CSS animations can't start until the current JavaScript task completes.
// Wrapping a function in |executeWithProgressBar()| will play animation and execute the
// function in the next JS task
export const executeWithProgressBar = (callback: () => void, disableProgressBar = window.popmenuNoProgressBarForButtons) => {
  if (disableProgressBar) {
    callback();
    return;
  }

  window.popmenuShowProgressBar?.();
  setTimeout(() => {
    callback();
    setTimeout(() => {
      window.popmenuHideProgressBar?.();
    }, 10); // Small delay allows covering slow multi-task JS operations.
  }, 0);
};

import type React from 'react';
import type { FieldApi, ValidateResult, AnyMeta } from 'react-form';
import type {
  CustomPageSection,
  RestaurantLocationAnsweringSetting,
  RestaurantLocationAnsweringSettingFallbackPhoneNumberInput,
} from '~/libs/gql/types';
import { toFloat, toInt } from './numbers';

const VALIDATION_TIMEOUT = 500;
const DOORDASH_DELIVERY_FEE = 6.99;
const DOORDASH_CANADA_DELIVERY_FEE = 8.5;

type SyncValidator<TFieldValue = string> = (value: TFieldValue, instance: FieldApi<TFieldValue>) => ValidateResult;
type Validator<TFieldValue = string> = (value: TFieldValue, instance: FieldApi<TFieldValue>) => ValidateResult | Promise<ValidateResult>;

export const blank = (value: string): ValidateResult => {
  if (value === undefined || value === null || value === 'null' || String(value).trim().length === 0) {
    return 'Cannot be blank';
  }
  return false;
};

const numMatches = (string: string, regex: RegExp) => string.match(regex)?.length ?? 0;

const validLiquidSyntax = (message: string): ValidateResult => {
  if (!message) {
    return false;
  }

  const openBraces = numMatches(message, /{{/g);
  const closeBraces = numMatches(message, /}}/g);

  return openBraces !== closeBraces ? 'opening braces {{ must have matching closing braces }}' : false;
};

const validEmail = (email: string): ValidateResult => {
  // eslint-disable-next-line no-useless-escape
  if (!email || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(email)) {
    return false;
  } else {
    return 'is invalid or does not match desired email address format.';
  }
};

export const validCheckoutEmail = (email: string): ValidateResult => {
  // eslint-disable-next-line no-useless-escape
  if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(email)) {
    return false;
  } else {
    return 'address is invalid.';
  }
};

const validPhone = (phone: string): ValidateResult => {
  const regex = /^(\()?[2-9]{1}\d{2}(\))?(-|\s)?[2-9]{1}\d{2}(-|\s)\d{4}$/;
  if (!phone || regex.test(phone)) {
    return false;
  } else {
    return 'Invalid or does not match desired format.';
  }
};

export const validCheckoutPhone = (phone: string): ValidateResult => {
  const regex = /^(\()?[2-9]{1}\d{2}(\))?(-|\s)?[2-9]{1}\d{2}(-|\s)\d{4}$/;
  if (regex.test(phone)) {
    return false;
  } else {
    return 'must be 10 digits.';
  }
};

export const validSquarePhone = (phone: string): ValidateResult => {
  const regex = /^\d{9,16}$/;
  if (regex.test(phone.replace(/[+ ()-]/g, ''))) {
    return false;
  } else {
    return 'must be between 9 and 16 digits.';
  }
};

const htmlTag = (value: string): ValidateResult => {
  const content = value || '';
  const attributeCount = numMatches(content, /\w=("|')(.*?)("|')/g);
  const openingAttributeCount = numMatches(content, /\w(="|=')/g);
  if (openingAttributeCount !== attributeCount) {
    return 'Invalid HTML attributes';
  }

  const openingTagCount = numMatches(content, /<(?!area|br|embed|hr|img|input|link|meta|source|wbr)(\w[^>]*)>/g);
  const closingTagCount = numMatches(content, /<\/\w*>/g);
  if (openingTagCount !== closingTagCount) {
    return 'Unclosed HTML tags';
  }
  return false;
};

const iFrameTag = (value: string): ValidateResult => {
  const content = value || '';
  const iFrameCount = numMatches(content, /<iframe/g);
  const titleCount = numMatches(content, /title="[A-Za-z0-9$]/g);
  if (titleCount < iFrameCount) {
    return 'All <iframe /> tags must have a title attribute';
  }
  return false;
};

const imageTag = (value: string): ValidateResult => {
  const content = value || '';
  const imageCount = numMatches(content, /<img/g);
  const altTextCount = numMatches(content, /alt="[A-Za-z0-9$]/g);
  if (altTextCount < imageCount) {
    return 'All <img /> tags must have an alt attribute (Click the "T" icon and add a short photo description for the visually impaired)';
  }
  return false;
};

// prevents links created by internal email blocker from being saved
const containsProhibitedUrl = (value: string): ValidateResult => {
  if (value != null && /antigena.com/.test(value)) {
    return 'Sorry, this field contains a prohibited URL';
  }
  return false;
};

export const greaterThan = (value: string, min: number = 0): ValidateResult => {
  if (+value <= min) {
    return `Must be greater than ${min}`;
  }
  return false;
};

const validatorDictionary = {
  email: validEmail,
  liquid: validLiquidSyntax,
  notBlank: blank,
  phone: validPhone,
} as const;

export const htmlValidator: Validator = value => containsProhibitedUrl(value) || htmlTag(value) || imageTag(value) || iFrameTag(value);

const debounce = (validator: SyncValidator): Validator => (value, instance) => instance.debounce(() => validator(value, instance), VALIDATION_TIMEOUT);

export const setFieldMetaAndDebounce = (validator: SyncValidator): Validator => debounce((value, instance) => {
  const validationResult = validator(value, instance);

  const fieldMeta: AnyMeta = {
    error: validationResult || false,
  };

  instance.form.setFieldMeta(instance.fieldName, fieldMeta);

  return validationResult;
},
);

export const doorDashCanadaSubsidyValidator = /* @__PURE__ */debounce((value) => {
  if (blank(value)) {
    return 'Cannot be blank';
  } else if (parseFloat(value) > DOORDASH_CANADA_DELIVERY_FEE) {
    return 'Out of fee range';
  }
  return false;
});

export const doorDashSubsidyValidator = /* @__PURE__ */debounce((value) => {
  if (blank(value)) {
    return 'Cannot be blank';
  } else if (parseFloat(value) > DOORDASH_DELIVERY_FEE) {
    return 'Out of fee range';
  }
  return false;
});

export const messageContentValidator = /* @__PURE__ */debounce((value) => {
  if (blank(value)) {
    return blank(value);
  } else if (htmlTag(value)) {
    return `has ${htmlTag(value)}`;
  } else if (imageTag(value)) {
    return 'images require alt attributes (add a short photo description for the visually impaired)';
  }
  return false;
});

// currying sets up min and max values for the validator
// debounce breaks the function thus is not used
export const minMaxValidator = (min: number, max: number) => (value: string) => {
  const parsedValue = toInt(value);
  if (typeof parsedValue !== 'number') {
    return false;
  } else if (parsedValue < min) {
    return `Requires minimum of ${min}`;
  } else if (parsedValue > max) {
    return `Requires maximum of ${max}`;
  }
  return false;
};

export const minOneValidator = /* @__PURE__ */debounce((value) => {
  const parsedValue = toInt(value);
  if (typeof parsedValue === 'number' && parsedValue < 1) {
    return 'Requires minimum of 1';
  }
  return false;
});

export const greaterThanOrEqualZeroValidator = /* @__PURE__ */debounce((value) => {
  const parsedValue = toFloat(value);
  if (typeof parsedValue === 'number' && parsedValue < 0) {
    return 'Requires minimum of 0';
  }
  return false;
});

export const notBlankValidator = /* @__PURE__ */debounce(blank);

export const notBlankAndGreaterThanOrEqualZeroValidator = /* @__PURE__ */debounce((value) => {
  const parsedValue = toFloat(value);
  if (typeof parsedValue === 'number' && parsedValue < 0) {
    return 'Requires minimum of 0';
  } else if (blank(value)) {
    return 'Cannot be blank';
  }
  return false;
});

export const fallbackNumberValidator = (value: string | undefined, answeringSettings: RestaurantLocationAnsweringSetting): ValidateResult => {
  if (!value) {
    return 'Fallback Numbers cannot be blank';
  }
  const answeringPhone = answeringSettings.phone?.match(/\d/g)?.join('');
  const fallbackPhone = value.match(/\d/g)?.join('');
  const answeringPhoneWithPrefix = `1${answeringPhone}`;
  const regex = /^(\()?[2-9]{1}\d{2}(\))?(-|\s)?[2-9]{1}\d{2}(-|\s)\d{4}$/;
  if (!fallbackPhone) {
    return 'Fallback Numbers cannot be blank';
  }

  if (!regex.test(value)) {
    return 'Fallback Number is invalid or does not match desired format.';
  }

  if (answeringPhone === fallbackPhone || answeringPhoneWithPrefix === fallbackPhone) {
    return 'Fallback Numbers cannot be the AI Answering number';
  }

  return false;
};

export const checkForDuplicateFallbacks = (fallbackPhoneNumbers: RestaurantLocationAnsweringSettingFallbackPhoneNumberInput[]) => {
  const seenFallbackTypes = new Set<string | undefined>();
  const seenPhoneNumbers = new Set<Optional<string>>();
  let errorMessage: ValidateResult = false;

  fallbackPhoneNumbers.forEach(({ fallbackType, phoneNumber }) => {
    const lowercaseFallbackType = fallbackType?.toLowerCase();

    if (seenFallbackTypes.has(lowercaseFallbackType)) {
      errorMessage = ('answering.settings.error.duplicate_fallback_type');
      return;
    }
    seenFallbackTypes.add(lowercaseFallbackType);

    if (seenPhoneNumbers.has(phoneNumber)) {
      errorMessage = ('answering.settings.error.duplicate_phone_number');
      return;
    }
    seenPhoneNumbers.add(phoneNumber);
  });
  return errorMessage;
};

export const textLengthValidatorAndNotBlank = ({ max }: { max: number }) => (
  debounce((value) => {
    if (blank(value)) {
      return blank(value);
    }

    if (max < value.length) {
      let result = `must be not more than ${max}`;
      result += ' characters in length.';
      return result;
    }
    return false;
  })
);

export const giftCardImageValidator = (values: CustomPageSection) => {
  const isUnique = values.giftCardImages.reduce<Record<number, true> | false>((c, el) => {
    const cache = c;
    const imageId = el?.imageUploadedPhoto?.id;
    if (imageId === undefined) return cache;
    if (!cache) return false;
    if (cache[imageId]) return false;
    cache[imageId] = true;
    return cache;
  }, {});
  return isUnique ? false : 'Gift Card images must be unique';
};

export const locationSearchValidator = (values: CustomPageSection) => {
  const locationsId = values.selectedLocations.filter(({ locationId }) => locationId != null).map(({ locationId }) => locationId);
  const isUnique = (new Set(locationsId)).size === locationsId.length;
  return isUnique ? false : 'Locations must be unique';
};

export const greaterThanAndNotBlankValidator = (limit: number) => debounce((value) => {
  if (blank(value)) {
    return blank(value);
  } else if (greaterThan(value, limit)) {
    return greaterThan(value, limit);
  }
  return false;
});

interface Limits {
  min?: number;
  max?: number;
}

export const textLengthValidator = ({ min = 0, max }: Limits) => (
  debounce((value) => {
    if (!value) {
      return false;
    }

    if (min > value.length || (max != null && max < value.length)) {
      let result = min > 0 ? `must be between ${min} and ${max}` : `must be not more than ${max}`;
      result += ' characters in length.';
      return result;
    }
    return false;
  })
);

export const isTextLengthWithinRange = (text: string | undefined, limits: Limits) => !text || (limits.max == null || limits.max >= text.length) && (limits.min == null || limits.min <= text.length);

export const isTextLengthLessThan = (text: string | undefined, max: number | undefined) => !text || (max == null || max >= text.length);

export const formatCardExpiryDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  const eventData = event.nativeEvent && (event.nativeEvent as InputEvent).data;
  const prevExpiry = event.target.value.split(' / ').join('/');

  if (!prevExpiry) return null;
  let expiry = prevExpiry;
  if (/^[2-9]$/.test(expiry)) {
    expiry = `0${expiry}`;
  }

  if (prevExpiry.length === 2 && +prevExpiry > 12) {
    const [head, ...tail] = prevExpiry.split('');
    expiry = `0${head}/${tail.join('')}`;
  }

  if (/^1[/-]$/.test(expiry)) {
    return '01 / ';
  }

  const expiryMatches = expiry.match(/(\d{1,2})/g) || [];
  if (expiryMatches.length === 1) {
    if (!eventData && prevExpiry.includes('/')) {
      return expiryMatches[0];
    }
    if (/\d{2}/.test(expiry)) {
      return `${expiryMatches[0]} / `;
    }
  }
  if (expiryMatches.length > 2) {
    const [, month = null, year = null] = expiryMatches.join('').match(/^(\d{2}).*(\d{2})$/) || [];
    return [month, year].join(' / ');
  }
  return expiryMatches.join(' / ');
};

export const usStatesList = () => ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'Washington DC', 'West Virginia', 'Wisconsin', 'Wyoming'];

export const samePasswordValidator = /* @__PURE__ */debounce((value, instance) => {
  if (blank(value)) {
    return blank(value);
  } else if (value === instance.form.values.password) {
    return false;
  } else {
    return ' does not match.';
  }
});

export const passwordValidator = /* @__PURE__ */debounce((value) => {
  if (blank(value)) {
    return blank(value);
  } else {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[Pp][Oo][Pp][Mm][Ee][Nn][Uu])(?=.*[@#$!%^*?&\-_=+`~[\]{}():;<>/.,'"])[A-Za-z\d@#$!%^*?&\-_=+`~[\]{}():;<>/.,'"]{8,}$/;
    if (!passwordRegex.test(value)) {
      return ' does not meet minimum requirements.';
    }
  }
  return false;
});

export const phoneValidator = /* @__PURE__ */debounce((value) => {
  if (blank(value)) {
    const regex = /^(\()?[2-9]{1}\d{2}(\))?(-|\s)?[2-9]{1}\d{2}(-|\s)\d{4}$/;
    if (!regex.test(value)) {
      return 'number is invalid or does not match desired format.';
    }
  }
  return false;
});

export const urlValidator = /* @__PURE__ */debounce((value) => {
  if (!blank(value)) {
    if (value.length > 0) {
      const phoneRegex = /^(tel:)/;
      const mailtoRegex = /^(mailto:)/;
      const uriRegex = /^(http|https):\/\/[^ "]+$/;
      const draftRegex = /draft=true/g;
      if (!phoneRegex.test(value) && !mailtoRegex.test(value) && !uriRegex.test(value) || draftRegex.test(value)) {
        return 'does not match desired format';
      }
    }
  }
  return false;
});

export const menuItemCartNameValidator = (max = 50) => (
  debounce((value) => {
    if (!value) {
      return false;
    }

    if (max < value.length) {
      return `must be not more than ${max} characters`;
    }

    if (value.includes('@')) {
      return 'must not contain any restricted characters';
    }
    return false;
  })
);

export const multiValidator = (validators: (SyncValidator | keyof typeof validatorDictionary)[]): Validator => setFieldMetaAndDebounce((value, instance) => {
  let validationResult: ValidateResult | Promise<ValidateResult> = false;
  for (const validator of validators) {
    const resolvedValidator: SyncValidator = typeof validator === 'function' ?
      validator :
      validatorDictionary[validator];

    const result = resolvedValidator(value, instance);

    if (result !== false) {
      validationResult = result;
      break;
    }
  }
  return validationResult;
});

export const isPositiveNumber = (value: string) => {
  const validNumber = new RegExp(/^\d*\.?\d*$/);

  return validNumber.test(value);
};

export const numberValidator = (value: string, message: string) => {
  if (isPositiveNumber(value)) {
    return false;
  }

  return message;
};

export interface CustomImageElement extends HTMLImageElement {
  metadata: {
    bytes: number;
  };
}

export const imageSizeInBytesValidator = (image: CustomImageElement): string | false => {
  if (!image) {
    return false;
  }

  const minAllowedBytes = 10 * 1024; // 10 KB in bytes
  const maxAllowedBytes = 5 * 1024 * 1024; // 5 MB in bytes
  const { bytes } = image.metadata;

  if (bytes < minAllowedBytes) {
    return "Image is too small, per Google's guidelines. Minimum size: 10KB";
  }

  if (bytes > maxAllowedBytes) {
    return "Image is too big, per Google's guidelines. Max size: 5MB";
  }

  return false;
};

import React, { useCallback } from 'react';
import type { DialogTitleProps } from '@material-ui/core';
import {
  type BackdropProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type PaperProps,
  Slide,
  IconButton,
} from '@material-ui/core';
import type { TransitionProps } from '@material-ui/core/transitions';
import { Icon, type IconProps } from '@popmenu/common-ui';
import { X as XIcon } from '@popmenu/web-icons';

import { executeWithProgressBar } from '~/utils/postponed';
import { useWindowSizeContext } from '../../shared/WindowSizeProvider';
import { classNames, DarkThemeProvider, makeStyles } from '../../utils/withStyles';
import modalStyles from '../../assets/jss/shared/modalStyles';

import { toSnakeCase } from '../../utils/strings';
import { useCurrentSession } from '../../shared/CurrentSessionProvider';

type TransitionComponent = React.ComponentType<
  TransitionProps & { children?: React.ReactElement }
>;

const LeftTransition: TransitionComponent = React.forwardRef((props, ref) => (
  <Slide ref={ref} direction="left" {...props} />
));

const UpTransition: TransitionComponent = React.forwardRef((props, ref) => (
  <Slide ref={ref} direction="up" {...props} />
));

const TRANSITION_COMPONENTS = {
  left: LeftTransition,
  popover: null,
  up: UpTransition,
};

type Transition = 'left' | 'popover' | 'up';

const transitionComponentProps = (transition: Transition | undefined) => {
  if (!transition) {
    return null;
  }

  const componentTransition = TRANSITION_COMPONENTS[transition];
  if (componentTransition) {
    return {
      TransitionComponent: componentTransition,
    };
  }
  return null;
};

const useStyles = makeStyles(modalStyles);

export type BasicModalProps = {
  actions?: React.ReactNode,
  actionsClassName?: string,
  BackdropProps?: Partial<BackdropProps>,
  backgroundColor?: string,
  centerTitle?: boolean,
  children?: React.ReactNode,
  className?: string,
  closeButton?: boolean,
  // TODO: sc-79132 closeModal should be required unless closeButton is definitely false
  closeModal?: () => void,
  contentClassName?: string,
  dark?: boolean,
  DialogContentProps?: object,
  DialogProps?: object,
  DialogTitleProps?: DialogTitleProps,
  dialogTitleStyles?: string,
  disableBackdropClick?: boolean,
  disableEnforceFocus?: boolean,
  disableEscapeKeyDown?: boolean,
  disableTransition?: boolean,
  fullScreen?: boolean,
  fullScreenMobile?: boolean,
  hidden?: boolean,
  hideBackdrop?: boolean,
  icon?: IconProps['icon'],
  mobileSmall?: boolean,
  paper?: boolean,
  paperClass?: classNames.Argument,
  PaperProps?: Partial<PaperProps>,
  scroll?: 'body' | 'paper',
  show: boolean,
  size?: 'lg' | 'md' | 'sm' | 'xl',
  style?: React.CSSProperties,
  title?: MaybeFormattedMessage,
  transition?: Transition,
  transitionDelay?: number,
  transitionDuration?: number,
};

// eslint-disable-next-line react/require-default-props -- destructured later
const BasicModal = (props: BasicModalProps) => {
  const {
    actions = null,
    actionsClassName = undefined,
    BackdropProps = {},
    centerTitle = false,
    children = null,
    className = undefined,
    closeButton = true,
    closeModal = () => {
      // do nothing
    },
    contentClassName = undefined,
    dark = false,
    DialogContentProps = {},
    DialogProps = {},
    DialogTitleProps = {},
    dialogTitleStyles = undefined,
    disableBackdropClick = true,
    disableEnforceFocus = false,
    disableEscapeKeyDown = false,
    disableTransition = false,
    fullScreenMobile = false,
    hidden = false,
    hideBackdrop = false,
    icon = undefined,
    mobileSmall = false,
    paper = true,
    paperClass = undefined,
    PaperProps = {},
    scroll = 'body',
    show = false,
    size = 'lg',
    style = {},
    title = undefined,
    transition = 'up',
    transitionDelay = 0,
    transitionDuration = undefined,
  } = props;

  const { isMobile } = useWindowSizeContext();
  const { backgroundColor, fullScreen = false } = isMobile && fullScreenMobile ?
    { backgroundColor: undefined, fullScreen: true } :
    props;

  const classes = useStyles({ backgroundColor });

  const session = useCurrentSession();
  const isThemeDark = session.user?.adminPaletteType === 'admin_palette_dark';
  let transitionProps: TransitionProps & { role: string } = {
    role: 'presentation', // HACK: material-ui provides role="none presentation" by default, which fails Lighthouse
    timeout: disableTransition ? 0 : transitionDuration || undefined,
  };

  if (transitionDelay) {
    transitionProps = {
      ...transitionProps,
      style: {
        transitionDelay: `${transitionDelay}ms`,
      },
    };
  }

  const onClose = useCallback((_event, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }
    if (typeof closeModal === 'function') {
      closeModal();
    }
  }, [closeModal, disableBackdropClick]);

  let titleId;
  if (title) {
    titleId = typeof title === 'string' ? `${toSnakeCase(title).replaceAll('_', '-')}-dialog-title` : 'dialog-title';
  }

  return (
    <Dialog
      hidden={hidden}
      hideBackdrop={hideBackdrop}
      aria-labelledby={titleId}
      style={style}
      classes={{
        paper: classNames(
          classes[fullScreen ? 'modalPaperFullscreen' : `modal-paper-${size}` as const],
          dark ? classes.modalPaperDark : null,
          paper ? null : classes.modalPaperHidden,
          'pm-modal-paper',
          className,
          backgroundColor ? classes.modalPaperNoBackground : null,
          mobileSmall ? classes.modalPaperMobileSmall : null,
          paperClass,
        ),
        root: classNames(
          classes.modalRoot,
          'pm-modal',
        ),
        scrollBody: classNames(
          className,
          classes.modalScrollBody,
          mobileSmall ?
            classes.modalScrollBodyMobileSmall :
            classes.modalScrollBody,
          (fullScreen || mobileSmall) ?
            classes.modalScrollBodyZeroSpacing : null,
          'pm-modal-body',
        ),
      }}
      BackdropProps={{
        className: classNames(
          classes.modalBackdrop,
          'pm-modal-backdrop',
        ),
        ...BackdropProps,
      }}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullWidth
      maxWidth={fullScreen ? 'xl' : size}
      onClose={onClose}
      open={show}
      PaperProps={PaperProps}
      scroll={scroll}
      TransitionProps={transitionProps}
      {...transitionComponentProps(transition)}
      {...DialogProps}
    >
      {(title || icon) && (
        <DialogTitle id={titleId} className={classNames(centerTitle ? classes.centeredDialogTitle : classes.dialogTitle, dialogTitleStyles)} {...DialogTitleProps}>
          {icon && <Icon aria-hidden className={classes.dialogTitleIcon} icon={icon} />}
          {title}
        </DialogTitle>
      )}
      <DialogContent
        className={classNames(
          classes.dialogContent,
          contentClassName,
          (fullScreen || mobileSmall) ?
            classes.dialogContentZeroSpacing : null,
        )}
        {...DialogContentProps}
        style={{ overflow: scroll === 'paper' && !fullScreen ? 'hidden auto' : 'visible' }}
      >
        <React.Fragment>
          {closeButton && (
            <div className={scroll === 'paper' ? classes.closeButtonWrapper : undefined}>
              <IconButton
                aria-label="Close Dialog"
                className={classNames(
                  scroll === 'paper' ? classes.closeButtonPaper : classes.closeButton,
                  dark ? classes.closeButtonDark : null,
                  paper ? null : classes.closeButtonPaperHidden,
                )}
                color="inherit"
                data-cy="close_modal_button"
                onClick={(() => executeWithProgressBar(() => closeModal?.()))}
                size="medium"
              >
                <Icon icon={XIcon} />
              </IconButton>
            </div>
          )}
          {dark || isThemeDark ? (
            <DarkThemeProvider>
              {typeof children === 'function' ? children() : children}
            </DarkThemeProvider>
          ) : (typeof children === 'function' ? children() : children)}
        </React.Fragment>
      </DialogContent>
      {actions && (
        <DialogActions className={classNames(classes.dialogActions, actionsClassName)}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default BasicModal;

import type { MenuPageEvent, MenuPageState } from '../../types';

const initialViewState: MenuPageState = {
  filters: [],
  loadableSections: [],
  sectionIntersections: [],
  sectionsWithFilteredItems: [],
};

// Handle view event types here. Cases are determined by existing state value, then type of event in that existing state
export const menuPageReducer = (prevState: MenuPageState = initialViewState, event: MenuPageEvent): MenuPageState => {
  const nextState = { ...prevState };
  switch (event.type) {
    case 'updateFilters': {
      const match = nextState.filters.find(filter => filter.id === event.filter.id);
      if (match) {
        nextState.filters = nextState.filters.filter(filter => filter.id !== match.id);
      } else {
        nextState.filters = [...nextState.filters, event.filter];
      }
      nextState.sectionsWithFilteredItems = [];
      break;
    }
    case 'sectionEntersIntersection':
      if (!nextState.loadableSections.includes(event.sectionId)) {
        nextState.loadableSections = [...nextState.loadableSections, event.sectionId];
      }
      if (!nextState.sectionIntersections.includes(event.sectionId)) {
        nextState.sectionIntersections = [...nextState.sectionIntersections, event.sectionId];
      }
      break;
    case 'sectionExitsIntersection':
      nextState.sectionIntersections = nextState.sectionIntersections.filter(sectionIntersection => sectionIntersection !== event.sectionId);
      break;
    case 'updateSectionFilteredItems': {
      if (event.hasItems) {
        // Add the new section ID and ensure uniqueness
        nextState.sectionsWithFilteredItems = [
          ...nextState.sectionsWithFilteredItems,
          event.sectionId,
        ].filter((element, index, array) => array.indexOf(element) === index);
      } else {
        // Remove the section ID
        nextState.sectionsWithFilteredItems = nextState.sectionsWithFilteredItems
          .filter(id => id !== event.sectionId);
      }
      break;
    }
  }
  return nextState;
};
